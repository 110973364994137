import { createSlice } from '@reduxjs/toolkit'
import { api, authApi } from '../../api'

// Slice

const slice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: [],
    error: false,
    isLoading: false,
  },
  reducers: {
    getNotificationsSuccess: (state, action) => {
      state.notifications = action.payload;
      state.isLoading = false;
    },
    createNotificationSuccess: (state, action) => {
      state.notifications.push(action.payload);
      state.isLoading = false;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
    isLoading: (state) => {
      state.isLoading = true;
    },
  },
});

export default slice.reducer

// Actions

const {
  hasError,
  isLoading,
  getNotificationsSuccess,
  createNotificationSuccess,
} = slice.actions;

export const getNotifications = () => async dispatch => {
  try {
    dispatch(isLoading());
    const returnData = await api.get('/notifications')
    dispatch(getNotificationsSuccess(returnData.data));
  } catch (e) {
    console.log(e);
    if(!e?.data){ return dispatch(hasError('An error occurred')) }
    const { data } = e.response;
    return dispatch(hasError(data.message))
  }
}

export const createNotification = (data, closeDialog) => async dispatch => {
  if(!data){ return dispatch(hasError('No data provided')) }
  console.log('creating notification reducer witth data:', data)
  try {
    dispatch(isLoading());
    const returnData = await authApi.post('/notifications', data)
    dispatch(createNotificationSuccess(returnData.data));
    closeDialog();
  } catch (e) {
    console.log(e);
    if(!e){ return dispatch(hasError('An error occurred')) }
    if(e.response?.data){
      const { data } = e.response;
      return dispatch(hasError(data.message ?? ''))
    } else {
      return dispatch(hasError('Unknown error'))
    }
  }
}